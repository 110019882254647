import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

import spot from '../assets/img/spot-icon.png';
import door from '../assets/img/door-icon.svg';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    locale: 'en',
    logoUrl,
    dictionaryOverrides: {
      'made-by-text': 'Omniyat',
      'made-by-link': 'https://www.omniyat.com/residential/anwa-aria-by-omniyat',
      'instructions-hint-text':
        'The actual finishes and external view may vary and this is illustration purpose only'
    },
    colors: {
      main: '#414141',
      mainText: '#FFFFFF'
    },
    panoramaIcons: { spot, door },
    scales: ['x05', 'x1'],
    scaleButtonType: 'text',
    enableCameraTransitionBetweenPanoramas: false,
  }

  new Widget('#widget', options);
}

const cursor = document.querySelector('.custom-cursor');

const setPosition = ({ x, y }) => {
  cursor.style.setProperty('--cursor-x', x);
  cursor.style.setProperty('--cursor-y', y);
};

const onMouseMove = (event) => {
  const { clientX, clientY, target } = event;
  const scaleElement = target.closest('.cursor-scale');

  if (scaleElement) {
    cursor.classList.add('custom-cursor--scale');
  } else {
    cursor.classList.remove('custom-cursor--scale');
  }

  setPosition({ x: clientX, y: clientY });
};

window.addEventListener('mousemove', (e) => onMouseMove(e));

const startScaleTime = (switchToogle = false) => {
  const scaleTime = setInterval(() => {
    const select = document.querySelector('.widget-tab-button--active');
    const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');
    if (select && scale) {
      select.after(scale);
      if (switchToogle) {
        const scaleImg = document.querySelector('.widget-tab__panorama-controls-button--scale img');
        scaleImg.style.setProperty('width', '25px', 'important');
      }
      clearInterval(scaleTime);
    }
  }, 100);
};

const startSelectFloorTime = () => {
  const selectTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const select = document.querySelector('.floors-select');
    if (select && buttons) {
      buttons.append(select);
      clearInterval(selectTime);
    }
  }, 100);
};

const copyWidgetEvents = () => {
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--prev').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--prev').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--next').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--next').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab__isometry-button').addEventListener('click', () => {
    document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrows').remove();
    startArrowsTime();
  });
};

const startArrowsTime = () => {
  const arrowsTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const arrows = document.querySelector('.widget-tab__isometry-arrows');
    if (buttons && arrows) {
      buttons.append(arrows.cloneNode(true));
      copyWidgetEvents();
      clearInterval(arrowsTime);
    }
  }, 100);
};

const redisignButtons = () => {
  startSelectFloorTime();
  startScaleTime();
  startArrowsTime();
  let scaleSwitch = false;

  document.addEventListener('click', (e) => {
    if (e.target.innerHTML === '2D') {
      const buttons = document.querySelector('.widget-tab-buttons');
      const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');
      buttons.removeChild(scale);

      startScaleTime(true);
      scaleSwitch = true;
    };
    if (e.target.innerHTML === '3D') {
      const buttons = document.querySelector('.widget-tab-buttons');
      const arrows = document.querySelector('.widget-tab__isometry-arrows');
      buttons.removeChild(arrows);
      startArrowsTime();
    };
  });
};

createWidget('https://www.omniyat.com/residential/anwa-aria-by-omniyat').then(() => {
  redisignButtons();
});
